.form-upload-container {
  width: 100%;
}

.preview-image {
  max-width: 100%;
}

.full-width-picker {
  width: 100%;
}

.margin-top-button {
  margin-top: 16px;
}

.margin-right {
  margin-right: 16px;
} 