.monospace-text {
  font-family: monospace;
}

.status-active {
  color: green;
}

.status-inactive {
  color: red;
}

.layout-container {
  min-height: 100vh;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
}

.header-title {
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.header-user-info {
  color: white;
}

.content-container {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logout-button {
  color: white;
}

.sider-menu {
  height: 100%;
  border-right: 0;
}

.content-layout {
  padding: 24px;
}

.content-box {
  padding: 24px;
  margin: 0;
  min-height: 280px;
  background: #fff;
  border-radius: 4px;
}

.form-button-group {
  display: flex;
  justify-content: flex-end;
}

.teachers-table .ant-pagination {
  margin-top: 16px !important;
  text-align: right;
}

.teachers-table .ant-table-pagination-right {
  justify-content: flex-end;
}

.teachers-table .ant-table-thead > tr > th {
  background-color: #f7f7f7;
  font-weight: 600;
}

.serial-number-cell {
  font-weight: bold;
  color: #666;
  text-align: center;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .header-container {
    padding: 0 10px;
    flex-direction: column;
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .header-title {
    margin-bottom: 10px;
  }

  .content-layout {
    padding: 12px;
  }

  .content-box {
    padding: 12px;
  }

  /* 表格响应式调整 */
  .teachers-table .ant-table {
    font-size: 12px;
  }

  /* 调整操作按钮在手机上的显示 */
  .teachers-table .ant-table-cell .ant-space {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .teachers-table .ant-table-cell .ant-space .ant-btn {
    margin-bottom: 5px;
    width: 100%;
  }
}

@media (max-width: 576px) {
  .content-container {
    flex-direction: column;
    align-items: stretch;
  }

  .content-container .ant-btn {
    margin-top: 10px;
    width: 100%;
  }

  .teachers-table .ant-table-thead > tr > th,
  .teachers-table .ant-table-tbody > tr > td {
    padding: 8px 4px;
  }
}

/* 适应性布局调整 */
.ant-layout-sider {
  max-height: 100vh;
  overflow-y: auto;
}

/* 防止内容溢出 */
.ant-layout-content {
  overflow-x: auto;
}

/* 表格响应式容器 */
.responsive-table-container {
  width: 100%;
  overflow-x: auto;
}

/* 错误消息样式 */
.error-message {
  padding: 15px;
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  border-radius: 4px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.error-message p {
  margin-bottom: 0;
  color: #ff4d4f;
}

@media (max-width: 576px) {
  .error-message {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .error-message button {
    margin-top: 10px;
    width: 100%;
  }
} 