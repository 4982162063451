/* tailwindcss directives - ignore linting warnings */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* 全局样式 - 防止横向滚动 */
body {
  overflow-x: hidden;
  width: 100%;
  max-width: 100vw;
  margin: 0;
  padding: 0;
  font-family: SimSun, "宋体", "Times New Roman", Times, serif;
  color: #333;
  background-color: #fafafa;
}

/* 移动端优化 */
@media (max-width: 768px) {
    /* 移动端页面优化 - 防止横向滚动 */
    html, body {
        overflow-x: hidden;
        width: 100%;
        position: relative;
    }

    /* 手机端字体样式 */
    .mobile-text {
        font-family: 'Noto Sans SC', sans-serif !important;
    }

    /* 手机端主页教师信息字体 */
    .grid > div:not(.preserve-scroll *) {
        font-family: SimSun, "宋体", serif !important;
    }
    
    /* 确保教师姓名、教学年限、学历等都使用宋体 */
    .grid h3:not(.preserve-scroll *),
    .grid span:not(.preserve-scroll *),
    .grid div:not(.preserve-scroll *) {
        font-family: SimSun, "宋体", serif !important;
    }

    /* 手机端教师卡片样式优化 */
    .mobile-teacher-card {
        background: linear-gradient(to bottom right, #fff, #f8f8f8);
        border: 1px solid #eee;
        padding: 1.25rem;
    }

    .teacher-name {
        font-size: 1.5rem;
        letter-spacing: 1px;
        background: linear-gradient(120deg, #333 0%, #666 100%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 0.75rem;
    }

    .teacher-info {
        font-size: 0.95rem;
        color: #666;
        letter-spacing: 0.5px;
    }

    .education-title {
        font-size: 1.1rem;
        color: #444;
        border-bottom: 2px solid #F5C518;
        display: inline-block;
        padding-bottom: 2px;
        margin-bottom: 1rem;
    }

    .education-year {
        color: #F5C518;
        font-size: 0.9rem;
        margin-bottom: 0.25rem;
    }

    .education-school {
        font-size: 1rem;
        color: #333;
    }

    .education-type {
        font-size: 0.9rem;
        color: #666;
        background: #f5f5f5;
        padding: 2px 8px;
        border-radius: 4px;
    }

    /* 图标样式优化 */
    .fas.fa-id-badge,
    .fas.fa-graduation-cap {
        font-size: 1rem;
        background: linear-gradient(120deg, #F5C518 0%, #E5B408 100%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .background-image {
        object-fit: contain;
        width: 100%;
        max-width: 100%;
        margin: 0 auto;
        max-height: calc(100vh - 80px);
    }

    /* 移动端标题图片样式 */
    .title-image {
        max-width: 75%;
    }
}

/* 小屏幕进一步优化 */
@media (max-width: 480px) {
    /* 移除未使用的hero-section样式 */
}

/* 处理异形屏适配 */
@supports (padding: env(safe-area-inset-top)) {
    /* 移除未使用的hero-section样式 */
}

/* 优化图片加载 */
/* 移除未使用的hero-section::before样式 */

/* 页面容器 */
.page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

/* 主要内容区 */
.main-content {
    flex-grow: 1;
}

/* 滚动平滑过渡 */
html {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

/* 隐藏滚动条但保持滚动功能 */
@media (min-width: 769px) {
    * {
        -ms-overflow-style: none; /* IE and Edge */
        /* scrollbar-width: none; */ /* Firefox */
        
        /* 使用Webkit的方式隐藏滚动条 */
    }
    
    /* 添加Webkit浏览器的滚动条隐藏 */
    *::-webkit-scrollbar {
        display: none;
    }
}

/* Antd 相关自定义样式 */
.ant-btn.rounded-button {
    border-radius: 8px !important;
}

.ant-modal-content {
    border-radius: 12px;
}

/* 自定义工具类 */
.hover\:shadow-md {
    transition: box-shadow 0.3s ease;
}

.hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.lang-menu-container {
    position: relative;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeIn {
  animation: fadeIn 0.6s ease-out forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.animate-slideIn {
  animation: slideIn 0.5s ease-out forwards;
}

@keyframes scaleUp {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animate-scaleUp {
  animation: scaleUp 0.4s ease-out forwards;
}

/* 保持模态框打开时的滚动位置 */
.preserve-scroll {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preserve-scroll .ant-modal {
  position: relative !important;
  top: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  transform: none !important;
}

/* 确保模态框内容区域不会超出视口 */
.preserve-scroll .ant-modal-content {
  max-height: 90vh;
  margin: 20px 0;
}

/* 防止模态框打开时页面滚动 */
.preserve-scroll .ant-modal-wrap {
  position: fixed;
  overflow: hidden;
}

/* 防止模态框打开时页面滚动重置 */
.no-scroll-reset {
  position: fixed !important;
}

.no-scroll-reset .ant-modal {
  position: fixed !important;
  margin: 0 auto;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
}

/* 文字字体设置 */
.text-gray-600,
.text-gray-700,
.text-gray-800,
p,
span,
h3,
h4,
div {
  font-family: "Times New Roman", Times, serif;
}

/* 背景图片样式 - 统一重写 */
.background-image-container {
  position: relative;
  width: 100%;
  overflow-x: hidden; /* 防止横向滚动 */
}

.background-image {
  display: block;
  max-height: calc(100vh - 80px); /* 减去导航栏高度 */
}

/* 桌面端背景图片样式 */
@media (min-width: 769px) {
  .background-image {
    width: 100vw; /* 视口宽度 */
    max-width: none;
    object-fit: cover; /* 改为cover以占满宽度 */
    margin-left: calc(-50vw + 50%); /* 使图片超出容器居中 */
    margin-right: calc(-50vw + 50%);
  }
}

/* 移动端背景图片样式 */
@media (max-width: 768px) {
  .background-image {
    object-fit: contain; /* 移动端保持原图比例 */
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }
}

/* 标题图片样式 */
.title-image-container {
  width: 100%;
  background-color: #fff;
  overflow: hidden;
}

.title-image {
  max-width: 100%;
  height: auto;
  display: block;
}

/* 桌面端标题图片样式 */
@media (min-width: 769px) {
  .title-image {
    max-width: 25%;
  }
}

/* 移动端标题图片样式 */
@media (max-width: 768px) {
  .title-image {
    max-width: 75%;
  }
}

/* AdminPanel 组件样式 */
.avatar-preview {
  margin-bottom: 10px;
}

.avatar-image {
  max-width: 100px;
  max-height: 100px;
}

.video-preview {
  margin-bottom: 10px;
}

.video-element {
  max-width: 200px;
  max-height: 150px;
}

.flex-row-item {
  display: flex;
  margin-bottom: 8px;
}

.flex-grow-1 {
  flex: 1;
}

.form-actions {
  text-align: right;
  margin-top: 20px;
}

.form-action-button {
  margin-right: 8px;
}

/* AdminDashboard 组件样式 */
.avatar-image-rounded {
  object-fit: cover;
  border-radius: 50%;
}

.avatar-placeholder {
  width: 50px; 
  height: 50px; 
  background-color: #f0f0f0; 
  border-radius: 50%; 
  display: flex; 
  align-items: center; 
  justify-content: center;
  color: #999;
}

/* TeacherDashboard 组件样式 */
.teacher-dashboard-layout {
  min-height: 100vh;
}

.teacher-dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.teacher-dashboard-title {
  color: white;
  font-size: 18px;
  font-weight: bold;
}

.teacher-dashboard-logout {
  color: white;
}

.teacher-profile-card {
  margin-bottom: 24px;
}

.teacher-profile-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.teacher-profile-info {
  margin-left: 20px;
}

/* 添加教师模态框样式 */
.ant-modal-body::-webkit-scrollbar {
  width: 8px;
}

.ant-modal-body::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.ant-modal-body::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.ant-modal-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ant-modal-centered .ant-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 表单间距优化 */
.ant-form-item {
  margin-bottom: 16px;
}

/* 教师表单样式 */
.teacher-form-container, 
.teacher-detailed-info-container {
  padding: 20px;
  background-color: #f0f2f5;
  min-height: 100vh;
}

.page-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  background-color: #fff;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.tab-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.error-container {
  max-width: 600px;
  margin: 100px auto;
  padding: 20px;
}

.image-preview, 
.avatar-preview, 
.video-preview {
  margin-bottom: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 8px;
  background-color: #fafafa;
}

.image-preview img, 
.avatar-preview img {
  max-width: 300px;
  max-height: 200px;
  display: block;
}

.form-button-group {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

/* 表格样式优化 */
.ant-table-tbody > tr > td {
  vertical-align: top;
}

/* 响应式调整 */
@media (max-width: 768px) {
  .page-header {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .page-header button {
    margin-bottom: 10px;
  }
}

/* 全局动画效果 */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeIn {
  animation: fadeIn 0.6s ease-out forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.animate-slideIn {
  animation: slideIn 0.5s ease-out forwards;
}

@keyframes scaleUp {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animate-scaleUp {
  animation: scaleUp 0.4s ease-out forwards;
}

/* 主页特定样式 */
.homepage-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.homepage-content {
  flex: 1;
}

/* 媒体查询 - 响应式设计 */
@media (max-width: 576px) {
  h1 {
    font-size: 1.8rem !important;
  }
  
  h2 {
    font-size: 1.5rem !important;
  }
  
  .ant-modal {
    max-width: 90% !important;
  }
}

/* 修复 Antd Modal 在移动端的样式 */
@media (max-width: 768px) {
  .ant-modal {
    width: 90% !important;
    margin: 0 auto;
  }
  
  .ant-modal-content {
    padding: 16px !important;
  }
  
  .ant-modal-body {
    padding: 16px 8px !important;
  }
}

/* 响应式表单样式 */
.form-card {
  width: 100%;
  overflow: hidden;
}

.page-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.responsive-image {
  max-width: 100%;
  height: auto;
  display: block;
  border-radius: 4px;
}

.responsive-video {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}

.upload-button {
  width: 100%;
}

/* 针对移动端优化页面头部 */
@media (max-width: 576px) {
  .page-header {
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 12px;
  }
  
  .page-header button {
    margin-bottom: 12px;
    margin-right: 0;
  }
  
  .form-card {
    padding: 12px;
  }
  
  .ant-form-item {
    margin-bottom: 12px;
  }
  
  .ant-divider {
    margin: 16px 0;
  }
  
  .avatar-preview,
  .video-preview {
    width: 100%;
    max-width: 250px;
    margin: 0 auto 10px;
  }
  
  .ant-space {
    display: block;
  }
  
  .ant-space-item {
    margin-right: 0 !important;
    margin-bottom: 10px;
  }
}

/* 防止表单内容溢出 */
.ant-form {
  max-width: 100%;
}

.ant-form-item-control-input,
.ant-input,
.ant-select,
.ant-picker {
  max-width: 100%;
}

/* 表单按钮在移动端的优化 */
@media (max-width: 768px) {
  .form-actions {
    display: flex;
    flex-direction: column;
  }
  
  .form-actions button {
    margin-bottom: 10px;
    width: 100%;
  }
  
  .form-actions button:last-child {
    margin-bottom: 0;
  }
}
  