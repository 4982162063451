.teacher-info-heading {
  margin-bottom: 24px;
}

.upload-status-text {
  margin-top: 8px;
  color: #999;
}

.upload-help-text {
  font-size: 12px;
  color: #999;
  margin-top: 4px;
} 