.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #f5f5f5;
}

.login-card {
  width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 30px 15px;
}

.login-header {
  text-align: center;
  margin-bottom: 20px;
}

.login-title {
  margin: 0 0 10px 0 !important;
  color: #333;
  font-weight: 500 !important;
}

/* 选项卡样式 */
.login-tabs .ant-tabs-nav::before {
  border-bottom: none;
}

.login-tabs .ant-tabs-tab {
  font-size: 16px;
  padding: 8px 16px;
}

.login-tabs .ant-tabs-tab-active {
  font-weight: bold;
}

.login-tabs .ant-tabs-ink-bar {
  background-color: #1890ff;
  height: 2px;
}

.login-form {
  padding: 10px 0;
}

/* 输入框样式 */
.login-input {
  height: 46px;
  border-radius: 4px;
}

.input-icon {
  color: #bfbfbf;
}

.ant-input-affix-wrapper-focused {
  box-shadow: none;
}

.login-error {
  color: #ff4d4f;
  margin-bottom: 16px;
}

/* 按钮样式 */
.login-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.back-button {
  min-width: 120px;
  height: 40px;
  border: 1px solid #d9d9d9;
  background: #fff;
  color: #333;
  border-radius: 4px;
}

.back-button:hover {
  color: #1890ff;
  border-color: #1890ff;
}

.submit-button {
  min-width: 120px;
  height: 40px;
  background-color: #ffc107;
  border-color: #ffc107;
  color: #333;
  border-radius: 4px;
}

.submit-button:hover {
  background-color: #ffb300;
  border-color: #ffb300;
  color: #333;
}

.home-button {
  width: 140px;
  height: 40px;
  background-color: #fff;
  color: #1890ff;
  border-color: #1890ff;
  transition: all 0.3s;
}

.home-button:hover {
  background-color: #e6f7ff;
} 