.home-container {
  width: 100%;
  font-family: SimSun, "宋体", "Times New Roman", Times, serif;
  color: #333;
}

/* 顶部区域样式 */
.home-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  background-color: rgba(255, 255, 255, 0.75); /* 白色背景，25%透明度 */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  position: fixed; /* 固定定位，使其在滚动时固定在顶部 */
  top: 0;
  left: 0;
  right: 0;
  z-index: 100; /* 确保始终在最上层 */
  width: 100%; /* 确保宽度100% */
  height: 70px; /* 固定高度 */
  -webkit-backdrop-filter: blur(5px); /* Safari支持 */
  backdrop-filter: blur(5px); /* 添加模糊效果，增强可读性 */
}

/* 添加内容容器 */
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  height: 100%; /* 使内容容器高度填满header */
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  height: 40px;
  margin-right: 8px;
  transition: transform 0.3s ease; /* 添加过渡效果 */
}

.logo:hover {
  transform: scale(1.05); /* 悬停时略微放大logo */
}

.logo-text {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.language-login {
  display: flex;
  align-items: center;
  gap: 20px;
}

.language-toggle {
  display: flex;
  align-items: center;
  gap: 8px;
}

.active-lang {
  color: #1890ff;
  font-weight: bold;
}

.language-switch {
  background-color: #f0f0f0;
}

.login-button {
  background-color: #ffc107;
  border-color: #ffc107;
  color: #333;
  font-weight: bold;
  border-radius: 4px; /* 圆角边框 */
  padding: 0 20px;
  transition: all 0.3s ease; /* 添加过渡效果 */
}

.login-button:hover {
  background-color: #ffb300;
  border-color: #ffb300;
  color: #333;
}

/* 移除所有旧的背景相关样式 */
.background-area, .background-content {
  display: none;
}

/* 新的背景容器样式 */
.background-container {
  width: 100%;
  margin-top: 0; /* 移除顶部间距 */
  text-align: center; /* 居中显示图片 */
  line-height: 0; /* 移除可能的间隙 */
  overflow: hidden; /* 防止溢出 */
}

/* 通过添加伪元素创建一个与导航栏高度相同的占位空间 */
.background-container::before {
  content: '';
  display: block;
  height: 70px; /* 与导航栏高度一致 */
  width: 100%;
}

.background-image {
  width: 100%; /* 图片宽度100% */
  height: auto; /* 高度自动，保持原比例 */
  display: block; /* 移除img标签的inline特性 */
}

/* 主内容区域样式 */
.main-content-area {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 40px 30px;
  background-color: #fff; /* 确保有背景色 */
  position: relative; /* 确保正确定位 */
  z-index: 1; /* 确保在其他元素之上 */
  margin-top: 0; /* 重置顶部边距 */
  margin-bottom: 10px; /* 设置底部边距为10px */
  border-radius: 8px; /* 添加圆角效果 */
  overflow: hidden; /* 确保内容不会溢出圆角边界 */
  font-family: SimSun, "宋体", "Times New Roman", Times, serif;
}

/* 标题图片样式 */
.section-title-container {
  text-align: center;
  margin: 10px 0; /* 上下边距相同 */
}

.section-title-image {
  width: 25%; /* 桌面端为原来的0.25倍 */
  height: auto;
  display: block;
  margin: 0 auto;
}

/* 将旧的文本标题样式保留但隐藏 */
.section-title {
  display: none;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

/* 教师网格样式 */
.teachers-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-bottom: 50px;
}

@media (max-width: 992px) {
  .teachers-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 576px) {
  .teachers-grid {
    grid-template-columns: 1fr;
  }
  
  .background-container {
    margin-top: 48px; /* 调整为超小屏幕顶部导航栏高度 */
  }
  
  .background-image {
    width: 100%; /* 图片宽度100% */
    height: auto; /* 高度自动，保持原比例 */
    display: block; /* 移除img标签的inline特性 */
    max-width: 100%; /* 确保不超过容器宽度 */
    object-fit: contain; /* 确保图片完整显示 */
    object-position: top; /* 从顶部开始显示 */
  }
  
  .background-content h1 {
    font-size: 2rem;
  }
  
  .background-content p {
    font-size: 1.2rem;
  }
  
  .home-header {
    padding: 8px 12px;
    height: 48px; /* 设置超小屏幕固定高度 */
  }
  
  .logo {
    height: 28px; /* 更小的logo */
  }
  
  .language-login {
    gap: 8px; /* 进一步减少间距 */
  }
  
  .login-button {
    padding: 0 8px;
    font-size: 12px;
    height: 28px;
  }
  
  .background-container {
    margin-top: 48px; /* 调整为超小屏幕顶部导航栏高度 */
  }
  
  .background-content {
    text-align: center;
    padding: 20px 10px;
  }
  
  .teacher-detail-modal {
    width: 95% !important;
    margin: 10px auto;
  }
  
  .teacher-detail-header {
    flex-direction: column;
  }
  
  .teacher-detail-image-container {
    margin-right: 0;
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .teacher-detail-image {
    max-width: 200px;
    height: auto;
  }
  
  .basic-info-container {
    padding: 15px;
  }
  
  .basic-info-grid {
    grid-template-columns: 1fr;
  }
  
  /* 移动端卡片优化 */
  .teacher-card {
    flex-direction: row;
    align-items: center;
    padding: 10px;
  }
  
  .teacher-image-container {
    position: relative;
    width: 50px;
    height: 50px;
    margin-right: 10px;
    overflow: visible;
  }
  
  .teacher-image {
    object-fit: cover;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    display: block; /* 避免底部间隙 */
    max-width: 100%; /* 限制最大宽度 */
    max-height: 100%; /* 限制最大高度 */
  }
  
  .teacher-info {
    flex: 1;
  }
  
  .teacher-name {
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  .teacher-details {
    flex-wrap: wrap;
  }
  
  .experience-tag, .education-tag, .age-tag {
    margin-bottom: 5px;
    padding: 2px 8px;
    font-size: 12px;
  }
  
  /* 详情视图中的列表响应式布局 */
  .education-list,
  .certificates-list,
  .experience-list,
  .teaching-content-list,
  .class-hour-list,
  .teaching-results-list {
    grid-template-columns: 1fr;
    gap: 12px;
  }
  
  .edu-item-detail,
  .certificate-item,
  .experience-item,
  .teaching-content-item,
  .class-hour-item,
  .teaching-result-item {
    padding: 12px;
  }
  
  /* 证书图片响应式 */
  .certificate-image img,
  .education-proof-image {
    max-height: 150px;
  }
  
  /* 视频容器响应式 */
  .video-container,
  .direct-video-container {
    height: auto;
    min-height: 200px;
  }
  
  /* 确保视频和图片不超出容器 */
  .video-container iframe,
  .direct-video-container video {
    max-width: 100%;
    height: auto;
  }
  
  .home-footer {
    padding: 25px 15px 10px; /* 进一步减小上下边距 */
  }
  
  .footer-sections {
    gap: 20px;
    margin-bottom: 20px;
    width: 100%; /* 超小屏幕使用全宽 */
  }
  
  .footer-section h4 {
    font-size: 16px;
    margin-bottom: 15px;
  }
  
  .footer-section p,
  .legal-links a,
  .copyright {
    font-size: 12px;
  }
  
  .legal-links {
    gap: 15px;
  }
  
  .background-container {
    margin-top: 48px; /* 调整为超小屏幕顶部导航栏高度 */
  }
}

@media (max-width: 576px) {
  .main-content-area {
    padding: 15px;
  }
  
  .section-title-container {
    margin: 5px 0; /* 超小屏幕上下边距相同，但更小 */
  }
  
  .section-title-image {
    width: 80%; /* 保持与平板相同 */
  }
  
  .teacher-detail-content {
    padding: 10px;
  }
  
  .teacher-detail-tabs .ant-tabs-tab {
    padding: 8px 0;
    font-size: 14px;
  }
  
  .teacher-detail-description h3 {
    font-size: 16px;
  }
  
  .teacher-detail-subtitle {
    font-size: 14px;
  }
  
  .video-container {
    height: auto;
  }
  
  .background-container {
    margin-top: 48px; /* 调整为超小屏幕顶部导航栏高度 */
  }
}

/* 底部区域样式 */
.home-footer {
  background-color: #f5f5f5;
  padding: 40px 30px 15px; /* 减小上下边距 */
  border-top: 1px solid #eee;
  width: 100%;
}

.footer-content {
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.footer-sections {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  margin-bottom: 30px; /* 减小底部边距 */
  direction: ltr; /* 从左到右排列 */
  width: 90%; /* 减小宽度 */
  margin-left: auto; /* 水平居中 */
  margin-right: auto; /* 水平居中 */
  max-width: 1200px; /* 限制最大宽度 */
}

.footer-section {
  text-align: left; /* 保持文本左对齐 */
}

/* 响应式调整 */
@media (max-width: 768px) {
  .home-header {
    padding: 10px 15px;
    height: 52px; /* 设置移动端固定高度 */
    background-color: rgba(255, 255, 255, 0.75); /* 保持与桌面端一致的透明度 */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* 添加阴影效果 */
    position: fixed; /* 确保在移动端也保持固定定位 */
    z-index: 100; /* 确保在移动端在背景上层 */
  }
  
  /* 通过添加伪元素创建一个与导航栏高度相同的占位空间 */
  .background-container::before {
    content: '';
    display: block;
    height: 52px; /* 与导航栏高度一致 */
    width: 100%;
  }
  
  .header-content {
    flex-direction: row;
    justify-content: space-between;
  }
  
  .logo-container {
    margin-bottom: 0;
    justify-content: flex-start;
  }
  
  .logo {
    height: 32px;
  }
  
  .language-login {
    width: auto;
    justify-content: flex-end;
    gap: 12px;
  }
  
  .language-toggle {
    gap: 4px;
  }
  
  .login-button {
    padding: 0 12px;
    font-size: 14px;
    height: 30px;
  }
  
  .background-container {
    margin-top: 0; /* 移除顶部边距 */
  }
  
  .background-image {
    margin-top: 0; /* 确保图片紧贴占位空间 */
  }
  
  .home-footer {
    padding: 30px 20px 15px; /* 减小上下边距 */
  }
  
  .footer-sections {
    grid-template-columns: 1fr;
    gap: 25px; /* 减小间距 */
    margin-bottom: 25px;
    width: 95%; /* 移动端稍微宽一点 */
  }
  
  .section-title-container {
    margin: 5px 0; /* 上下边距相同，但在移动端稍小 */
  }
  
  .section-title-image {
    width: 80%; /* 移动端为原来的0.6倍 */
  }
  
  /* 移动端教师卡片 */
  .teacher-card {
    padding: 15px;
  }
  
  .teacher-header {
    margin-bottom: 12px;
  }
  
  .teacher-name {
    font-size: 16px;
  }
  
  .teacher-image-container {
    width: 50px;
    height: 50px;
  }
  
  .gender-icon {
    width: 18px;
    height: 18px;
    font-size: 12px;
  }
  
  .info-item {
    margin-right: 15px;
  }
  
  .section-heading {
    font-size: 15px;
  }
  
  .show-more-button {
    font-size: 14px;
    padding: 8px 24px;
    border-width: 1.5px; /* 略微减小边框粗细 */
    transition: background-color 0.2s ease, color 0.2s ease; /* 确保只对颜色应用过渡效果 */
  }
  
}

@media (max-width: 576px) {
  .home-header {
    padding: 8px 12px;
    height: 48px; /* 设置超小屏幕固定高度 */
    background-color: rgba(255, 255, 255, 0.75); /* 保持一致的透明度 */
  }
  
  /* 调整伪元素占位空间的高度 */
  .background-container::before {
    height: 48px; /* 与超小屏幕导航栏高度一致 */
  }
  
  .logo {
    height: 28px;
  }
  
  .language-login {
    gap: 8px;
  }
  
  .login-button {
    padding: 0 8px;
    font-size: 12px;
    height: 28px;
  }
  
  .home-footer {
    padding: 25px 15px 10px; /* 进一步减小上下边距 */
  }
  
  .footer-sections {
    gap: 20px;
    margin-bottom: 20px;
    width: 100%; /* 超小屏幕使用全宽 */
  }
  
  .footer-section h4 {
    font-size: 16px;
    margin-bottom: 15px;
  }
  
  .footer-section p,
  .legal-links a,
  .copyright {
    font-size: 12px;
  }
  
  .legal-links {
    gap: 15px;
  }
  
  .background-container {
    margin-top: 0; /* 移除顶部边距，与导航栏无缝衔接 */
  }
  
  /* 超小屏幕教师卡片 */
  .teacher-card {
    padding: 12px;
  }
  
  .teacher-basic-info {
    flex-wrap: nowrap;
    white-space: nowrap;
  }
  
  .info-item {
    margin-right: 10px;
    margin-bottom: 0;
    flex-shrink: 0;
  }
  
  .info-icon {
    width: 20px;
    height: 20px;
    font-size: 12px;
  }
  
  .edu-item {
    flex-direction: column;
  }
  
  .edu-degree {
    text-align: left;
    margin-top: 4px;
    margin-left: 4px;
    color: #888;
  }
  
  .show-more-button {
    font-size: 13px;
    padding: 6px 20px;
    border-width: 1.5px;
    transition: background-color 0.2s ease, color 0.2s ease; /* 确保只对颜色应用过渡效果 */
  }
}

.footer-section h4 {
  font-size: 18px;
  margin-bottom: 20px;
  color: #333;
  font-weight: bold;
}

.footer-section p {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
  line-height: 1.6;
}

.legal-links {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.legal-links a {
  color: #666;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s ease;
}

.legal-links a:hover {
  color: #1890ff;
}

.copyright {
  text-align: center;
  font-size: 14px;
  color: #999;
  padding: 20px 0;
  border-top: 1px solid #eee;
}

/* 教师详情模态框样式 */
.teacher-detail-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 90vh;
  overflow: hidden;
  font-family: SimSun, "宋体", "Times New Roman", Times, serif;
}

.teacher-detail-modal .ant-modal-content {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 0;
  border-radius: 8px;
}

/* Desktop-specific styles with optimized padding */
@media (min-width: 577px) {
  .teacher-detail-modal .ant-modal-content {
    padding: 0;
  }
  
  .teacher-detail-modal .ant-modal-header {
    padding: 12px 16px 4px;
    border-radius: 8px 8px 0 0;
  }
  
  .teacher-detail-modal .ant-modal-body {
    padding: 8px 16px;
    max-height: 80vh;
    overflow-y: auto;
  }
  
  .teacher-detail-modal .ant-modal-footer {
    padding: 8px 16px;
    border-radius: 0 0 8px 8px;
  }
  
  .detail-section {
    margin-bottom: 12px;
    padding: 12px;
  }
}

/* Keep existing mobile styles */
.teacher-detail-modal .ant-modal-header {
  background-color: transparent;
  border-bottom: none;
  padding: 12px 16px 0; /* Reduce padding: top, horizontal, bottom */
  margin-bottom: 0;
}

.teacher-detail-modal .ant-modal-title {
  color: #333;
  font-weight: bold;
  font-size: 16px; /* Smaller title */
}

.teacher-detail-modal .ant-modal-body {
  padding: 12px 16px; /* Reduce padding from 24px to 12px/16px */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-height: calc(90vh - 90px); /* Reduce to accommodate smaller header/footer */
  background-color: #ffffff;
}

.teacher-detail-content {
  line-height: 1.6;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  background-color: #ffffff;
  padding: 0; /* Ensure no extra padding */
}

.teacher-detail-header {
  display: flex;
  padding-bottom: 8px;
  margin-bottom: 8px;
  align-items: center;
}

/* 头像容器 - 增大到2倍 */
.teacher-detail-image-container {
  position: relative;
  width: 100px; /* 原来是50px，增大到2倍 */
  height: 100px; /* 原来是50px，增大到2倍 */
  border-radius: 50%;
  overflow: visible; /* 改为visible以显示性别图标 */
  margin-right: 15px;
  border: none;
  flex-shrink: 0;
}

.teacher-detail-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

/* 添加性别图标到头像上 */
.teacher-detail-image-container::after {
  content: '♀'; /* 默认为女性符号 */
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #FF6B9C; /* 默认粉红色 - 女性 */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: white;
  z-index: 5; /* 确保在头像之上 */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.teacher-detail-image-container.male::after {
  content: '♂'; /* 男性符号 */
  background-color: #4D96FF; /* 蓝色 - 男性 */
}

.teacher-detail-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

/* 姓名 - 增大到2倍 */
.teacher-detail-info h2 {
  color: #000; /* 改为黑色 */
  margin-bottom: 8px;
  font-size: 36px; /* 原来是18px，增大到2倍 */
  font-weight: bold;
}

.teacher-detail-subtitle {
  color: #000; /* 改为黑色 */
  margin-bottom: 0;
  font-size: 14px;
}

/* 教师基本信息 */
.teacher-detail-basic-info {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

/* 信息项 - 教龄、学历、年龄 */
.teacher-detail-info-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

/* 信息图标 */
.teacher-detail-info-icon {
  display: inline-flex;
  width: 24px;
  height: 24px;
  background-color: #fffbe6;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  font-size: 14px;
  color: #FFCA28;
}

/* 信息文本 - 增大到2倍 */
.teacher-detail-info-text {
  font-size: 20px; /* 原来约10px, 增大到2倍 */
  color: #000; /* 改为黑色 */
}

.teacher-detail-button-container {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.chat-teacher-button {
  background-color: #F2C94C;
  border-color: #F2C94C;
  font-weight: 500;
  padding: 0 16px;
  height: 32px;
  font-size: 14px;
  white-space: nowrap;
}

/* Divider 样式 */
.teacher-detail-modal .ant-divider {
  display: none;
}

/* 教师详细信息模态框-垂直布局样式 */
.teacher-detail-sections {
  flex: 1;
  overflow-y: auto;
  padding-right: 6px;
  margin-right: -6px;
  padding-top: 4px;
}

.teacher-detail-sections::-webkit-scrollbar {
  width: 4px;
}

.teacher-detail-sections::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.teacher-detail-sections::-webkit-scrollbar-thumb {
  background: #FFCC00;
  border-radius: 3px;
}

.teacher-detail-sections::-webkit-scrollbar-thumb:hover {
  background: #F2C94C;
}

.detail-section {
  background-color: #fff;
  border-radius: 6px;
  padding: 12px;
  box-shadow: none;
  margin-bottom: 12px;
  border: none;
}

.detail-section:last-child {
  margin-bottom: 0;
}

.detail-section h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
  color: #000;
}

.edu-item-detail, .certificate-item, .experience-item, 
.teaching-content-item, .class-hour-item, .teaching-result-item, .ability-item {
  background-color: #f7f7f7;
  border-radius: 6px;
  padding: 10px 12px;
  margin-bottom: 8px;
  border: none;
  box-shadow: none;
}

/* 优化现有模态框内容显示 */
.teacher-detail-modal .ant-modal-body {
  padding: 24px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-height: calc(90vh - 110px); /* 减去header和footer的高度 */
  background-color: #ffffff;
}

.teacher-detail-modal .ant-modal-footer {
  border-top: none;
  padding: 6px 16px; /* Reduce padding from 10px to 6px */
}

.teacher-detail-modal .ant-btn-primary {
  background-color: #F2C94C;
  border-color: #F2C94C;
}

.teacher-detail-modal .ant-btn-primary:hover {
  background-color: #FFCC00;
  border-color: #FFCC00;
}

/* Multi-item sections styling in teacher detail modal */
.edu-item-detail, .certificate-item, .experience-item, 
.teaching-content-item, .class-hour-item, .teaching-result-item, .ability-item {
  background-color: #f7f7f7;
  border-radius: 6px;
  padding: 12px 16px;
  margin-bottom: 12px;
  border: none;
  box-shadow: none;
}

.edu-item-detail:last-child, .certificate-item:last-child, 
.experience-item:last-child, .teaching-content-item:last-child, 
.class-hour-item:last-child, .teaching-result-item:last-child, 
.ability-item:last-child {
  margin-bottom: 0;
}

/* Consistent typography inside items */
.edu-item-detail p, .certificate-item p, .experience-item p, 
.teaching-content-item p, .class-hour-item p, .teaching-result-item p,
.ability-item strong, .ability-item span {
  margin-bottom: 4px;
  color: #000;
  font-size: 14px;
}

.edu-item-detail p:last-child, .certificate-item p:last-child, 
.experience-item p:last-child, .teaching-content-item p:last-child, 
.class-hour-item p:last-child, .teaching-result-item p:last-child {
  margin-bottom: 0;
}

/* Remove any conflicting styles for ability-item */
.ability-item {
  margin-bottom: 12px;
}

.ability-item:last-child {
  margin-bottom: 0;
}

/* 私聊老师按钮 */
.chat-teacher-button {
  margin-top: 12px;
  background-color: #F2C94C;
  border-color: #F2C94C;
  font-weight: 500;
}

.chat-teacher-button:hover {
  background-color: #FFCC00;
  border-color: #FFCC00;
}

/* 二维码弹窗样式 */
.qrcode-modal .ant-modal-header {
  background-color: transparent;
  border-bottom: none;
  padding-bottom: 0;
}

.qrcode-modal .ant-modal-title {
  color: #333;
  font-weight: bold;
}

.qrcode-modal .ant-btn-primary {
  background-color: #F2C94C;
  border-color: #F2C94C;
}

.qrcode-modal .ant-btn-primary:hover {
  background-color: #FFCC00;
  border-color: #FFCC00;
}

.qrcode-modal .ant-modal-body {
  padding: 24px;
  text-align: center;
}

.qrcode-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.qrcode-image {
  width: 240px;
  height: 240px;
  object-fit: contain;
  border: none;
  padding: 10px;
  margin-bottom: 15px;
}

.qrcode-tip {
  color: #666;
  font-size: 14px;
  margin-top: 10px;
}

@media (max-width: 576px) {
  .qrcode-image {
    width: 200px;
    height: 200px;
  }
  
  .chat-teacher-button {
    width: 100%;
    margin-top: 10px;
  }
}

/* 视频容器自适应样式 */
.detail-section .video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9比例 */
  height: 0;
  overflow: hidden;
  background-color: #f8f8f8;
  border-radius: 6px;
  margin-bottom: 15px;
}

.detail-section .video-container iframe,
.detail-section .video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.detail-section .video-error {
  padding: 15px;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 6px;
  margin-bottom: 15px;
}

/* 教育背景图片样式 */
.detail-section .education-proof {
  margin-top: 10px;
}

.detail-section .education-proof-image {
  max-width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: contain;
  border-radius: 6px;
  cursor: pointer;
  transition: transform 0.3s ease;
  display: block;
}

.detail-section .education-proof-image:hover {
  transform: scale(1.02);
}

/* 专业证书图片样式 */
.detail-section .certificate-image {
  margin-top: 10px;
}

.detail-section .certificate-image img {
  max-width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: contain;
  border-radius: 6px;
  cursor: pointer;
  transition: transform 0.3s ease;
  display: block;
}

.detail-section .certificate-image img:hover {
  transform: scale(1.02);
}

/* 响应式调整 */
@media (max-width: 576px) {
  .detail-section .education-proof-image,
  .detail-section .certificate-image img {
    max-height: 150px;
    border: none;
  }
  
  .detail-section .video-container {
    padding-bottom: 75%; /* 4:3比例，在小屏上更合适 */
  }
  
  .image-preview-modal {
    width: 95% !important;
    margin: 0 auto;
  }
  
  .image-preview {
    max-height: 60vh;
  }
  
  /* 移动端教育背景字体缩小 */
  .teacher-card .teacher-education .section-heading {
    font-size: calc(16px * 1.6 * 0.8 * 0.8) !important; /* 在原有基础上再缩小0.8倍 */
  }
  
  .teacher-card .teacher-education .edu-period {
    font-size: calc(13px * 1.6 * 0.8) !important; /* 缩小到0.8倍 */
  }
  
  .teacher-card .teacher-education .edu-school {
    font-size: calc(14px * 1.6 * 0.8 * 0.8) !important; /* 在原有基础上再缩小0.8倍 */
  }
  
  .teacher-card .teacher-education .edu-degree {
    font-size: calc(14px * 1.6 * 0.8 * 0.8) !important; /* 在原有基础上再缩小0.8倍 */
  }
}

/* 图片预览模态框样式 */
.image-preview-modal .ant-modal-header {
  background-color: transparent;
  border-bottom: none;
  padding-bottom: 0;
}

.image-preview-modal .ant-modal-title {
  color: #333;
  font-weight: bold;
}

.image-preview-modal .ant-modal-body {
  padding: 24px;
  text-align: center;
}

.image-preview-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-preview {
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  max-width: 100%;
  max-height: 70vh;
}

/* 教师卡片样式 */
.teacher-card {
  background-color: #f9f9f9;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.teacher-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.teacher-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
}

.teacher-info-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.teacher-name {
  font-size: 29px;
  font-weight: bold;
  color: #333;
  margin: 0 0 10px 0;
}

.teacher-image-container {
  position: relative;
  width: 90px;
  height: 90px;
  margin-left: 15px;
  flex-shrink: 0;
  aspect-ratio: 1 / 1; /* 确保始终是1:1的宽高比例 */
}

.teacher-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.gender-icon {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: white;
  z-index: 1;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.gender-icon.female {
  background-color: #FF6B9C; /* 粉红色 */
}

.gender-icon.male {
  background-color: #4D96FF; /* 淡蓝色 */
}

.teacher-basic-info {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
  align-items: center;
}

.info-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 0;
  flex-shrink: 0;
}

.info-icon {
  display: inline-flex;
  width: 22px;
  height: 22px;
  background-color: #fffbe6;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  font-size: 14px;
  color: #FFCA28;
  flex-shrink: 0;
}

.info-text {
  font-size: 22px;
  color: #555;
  white-space: nowrap;
}

/* 移动端响应式样式 */
@media (max-width: 768px) {
  .teacher-header {
    flex-direction: row;
    align-items: center;
  }

  .teacher-info-container {
    flex: 1;
  }

  .teacher-name {
    font-size: 26px;
    margin-bottom: 5px;
  }

  .teacher-image-container {
    width: 84px;
    height: 84px;
    margin-left: 10px;
    aspect-ratio: 1 / 1; /* 确保始终是1:1的宽高比例 */
  }

  .info-item {
    margin-right: 12px;
  }

  .info-text {
    font-size: 20px;
  }
}

@media (max-width: 576px) {
  .teacher-header {
    flex-direction: row;
  }

  .teacher-info-container {
    flex: 1;
  }

  .teacher-name {
    font-size: 24px;
    margin-bottom: 5px;
  }

  .teacher-image-container {
    position: relative;
    width: 50px;
    height: 50px;
    margin-right: 10px;
    overflow: visible;
  }
  
  .teacher-image {
    object-fit: cover;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    display: block; /* 避免底部间隙 */
    max-width: 100%; /* 限制最大宽度 */
    max-height: 100%; /* 限制最大高度 */
  }

  .gender-icon {
    top: 0px;
    right: 0px;
    width: 18px;
    height: 18px;
    font-size: 12px;
    z-index: 2;
  }

  .info-text {
    font-size: 18px;
  }
}

.teacher-education {
  border-top: 1px solid #eee;
  padding-top: 15px;
}

.section-heading {
  font-size: 16px;
  color: #333;
  margin: 0 0 10px 0;
  font-weight: 500;
}

.edu-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

.edu-period-school {
  display: flex;
  flex-direction: column;
}

.edu-period {
  font-size: 13px;
  color: #c9aa07;
  font-weight: 500;
}

.edu-school {
  font-size: 14px;
  color: #333;
  display: inline-block;
}

.edu-degree {
  font-size: 14px;
  color: #666;
  text-align: left;
  margin-top: 4px;
}

/* 显示更多按钮容器 */
.show-more-container {
  display: flex;
  justify-content: center;
  margin: 30px 0 40px;
}

/* 显示更多按钮基本样式 */
.show-more-button {
  background-color: #FFCA28; /* 黄色背景 */
  color: #333;
  font-weight: 500;
  font-size: 16px;
  padding: 10px 30px;
  border: 2px solid #333;
  border-radius: 50px; /* 胶囊形状 */
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease; /* 只对颜色应用过渡效果 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden; /* 为波纹效果裁剪边界 */
}

/* 悬停效果 - 改为蓝色 */
.show-more-button:hover {
  background-color: #2196F3; /* 蓝色背景 */
  color: #fff; /* 文字改为白色以增加对比度 */
}

/* 点击效果 - 更深的蓝色 */
.show-more-button:active {
  background-color: #1976D2; /* 更深的蓝色 */
}

/* 聚焦效果 */
.show-more-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(33, 150, 243, 0.5); /* 蓝色光晕效果，减小尺寸 */
  background-color: #FFCA28; /* 保持黄色背景，防止聚焦时变蓝 */
  color: #333; /* 保持原始文字颜色 */
}

/* 只有在悬停且聚焦时才应用蓝色 */
.show-more-button:hover:focus {
  background-color: #2196F3;
  color: #fff;
}

/* 点击动画类 */
.button-clicked {
  animation: buttonPulse 0.15s ease-out; /* 减少动画时间，使恢复更快 */
  border-radius: 50px; /* 确保动画效果保持相同的圆角 */
  overflow: hidden; /* 确保内容不超出边界 */
}

@keyframes buttonPulse {
  0% {
    background-color: #1976D2; /* 立即变为蓝色 */
  }
  90% {
    background-color: #1976D2; /* 保持蓝色时间较长 */
  }
  100% {
    background-color: #FFCA28; /* 迅速恢复到黄色 */
  }
}

.teacher-info {
  flex: 1;
  font-family: SimSun, "宋体", "Times New Roman", Times, serif;
}

@media (max-width: 480px) {
  .teacher-name {
    font-size: 22px;
    margin-bottom: 5px;
  }

  .teacher-image-container {
    width: 65px;
    height: 65px;
    margin-left: 8px;
    aspect-ratio: 1 / 1; /* 确保始终是1:1的宽高比例 */
    flex-shrink: 0; /* 防止容器被挤压 */
    overflow: hidden; /* 确保内容不溢出 */
    max-width: 65px; /* 强制最大宽度 */
    max-height: 65px; /* 强制最大高度 */
    box-sizing: border-box; /* 确保边框计入尺寸 */
  }
  
  .teacher-image {
    object-fit: cover;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    display: block; /* 避免底部间隙 */
    max-width: 100%; /* 限制最大宽度 */
    max-height: 100%; /* 限制最大高度 */
  }
  
  .info-icon {
    width: 18px;
    height: 18px;
    margin-right: 4px;
    font-size: 10px;
  }

  .info-text {
    font-size: 16px;
  }

  .info-item {
    margin-right: 8px;
  }
}

/* 教师卡片中教育背景字体放大 */
.teacher-card .teacher-education .section-heading {
  font-size: calc(16px * 1.6 * 0.8); /* 先放大1.6倍，再缩小到0.8倍 */
}

.teacher-card .teacher-education .edu-period {
  font-size: calc(13px * 1.6); /* 放大1.6倍 */
}

.teacher-card .teacher-education .edu-school {
  font-size: calc(14px * 1.6 * 0.8);
  color: #333;
}

.teacher-card .teacher-education .edu-degree {
  font-size: calc(14px * 1.6 * 0.8);
  color: #666;
  text-align: right;
}

/* 确保本科和北京大学在同一行，本科放右边 */
.teacher-card .teacher-education .edu-item {
  display: flex;
  flex-direction: row; /* 改为行布局 */
  justify-content: space-between; /* 两端对齐 */
  align-items: center; /* 垂直居中 */
  margin-bottom: 8px;
}

/* 调整教育周期与学校的容器，避免过度扩展 */
.teacher-card .teacher-education .edu-period-school {
  display: flex;
  flex-direction: column;
  flex: 1; /* 允许伸展但不超过可用空间 */
  max-width: 70%; /* 限制最大宽度，为学位留出足够空间 */
}

/* 语言能力 */
.language-ability {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 16px;
  margin-top: 16px;
}

.ability-item {
  margin-bottom: 12px;
}

.ability-item strong {
  display: inline-block;
  min-width: 140px;
  color: #555;
}

/* 证书 */
.certificates-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 16px;
  margin-top: 16px;
}

.certificate-item {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.certificate-image {
  margin-top: 12px;
  max-width: 100%;
}

.certificate-image img {
  max-width: 100%;
  border-radius: 4px;
  border: 1px solid #eaeaea;
}

/* 教学经历 */
.experience-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 16px;
  margin-top: 16px;
}

.experience-item {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

/* 教学内容 */
.teaching-content-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 16px;
  margin-top: 16px;
}

.teaching-content-item {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

/* 课时标准 */
.class-hour-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 16px;
  margin-top: 16px;
}

.class-hour-item {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

/* 教学成果 */
.teaching-results-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 16px;
  margin-top: 16px;
}

.teaching-result-item {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

/* 教师卡片教育背景专用样式-最新版本 */
.teacher-card .teacher-education {
  border-top: 1px solid #eee;
  padding-top: 15px;
}

/* 教育背景标题 */
.teacher-card .teacher-education .section-heading {
  font-size: calc(16px * 1.6 * 0.8); /* 先放大1.6倍，再缩小到0.8倍 */
  color: #333;
  margin: 0 0 10px 0;
  font-weight: 500;
}

/* 教育信息项 */
.teacher-card .teacher-education .edu-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 8px;
}

/* 学校和时间容器 */
.teacher-card .teacher-education .edu-period-school {
  display: flex;
  flex-direction: column;
}

/* 教育时间段 */
.teacher-card .teacher-education .edu-period {
  font-size: calc(13px * 1.6);
  color: #c9aa07;
  font-weight: 500;
}

/* 学校名称 */
.teacher-card .teacher-education .edu-school {
  font-size: calc(14px * 1.6 * 0.8);
  color: #333;
}

/* 学位 */
.teacher-card .teacher-education .edu-degree {
  font-size: calc(14px * 1.6 * 0.8);
  color: #666;
  text-align: right;
}

/* 教师详细信息模态框-垂直布局样式 */
.teacher-detail-sections {
  max-height: 60vh;
  overflow-y: auto;
  padding-right: 10px;
}

.teacher-detail-sections::-webkit-scrollbar {
  width: 6px;
}

.teacher-detail-sections::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.teacher-detail-sections::-webkit-scrollbar-thumb {
  background: #FFCC00;
  border-radius: 3px;
}

.teacher-detail-sections::-webkit-scrollbar-thumb:hover {
  background: #F2C94C;
}

.detail-section {
  background-color: #fff;
  border-radius: 6px;
  padding: 12px;
  box-shadow: none;
  margin-bottom: 12px;
  border: none;
}

.detail-section:last-child {
  margin-bottom: 0;
}

.detail-section h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
  color: #000;
}

.edu-item-detail, .certificate-item, .experience-item, 
.teaching-content-item, .class-hour-item, .teaching-result-item, .ability-item {
  background-color: #f7f7f7;
  border-radius: 6px;
  padding: 10px 12px;
  margin-bottom: 8px;
  border: none;
  box-shadow: none;
}

/* 优化现有模态框内容显示 */
.teacher-detail-modal .ant-modal-body {
  padding: 24px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-height: calc(90vh - 110px); /* 减去header和footer的高度 */
  background-color: #ffffff;
}

.teacher-detail-modal .ant-modal-footer {
  border-top: none;
  padding: 6px 16px; /* Reduce padding from 10px to 6px */
}

.teacher-detail-modal .ant-btn-primary {
  background-color: #F2C94C;
  border-color: #F2C94C;
}

.teacher-detail-modal .ant-btn-primary:hover {
  background-color: #FFCC00;
  border-color: #FFCC00;
}

/* Multi-item sections styling in teacher detail modal */
.edu-item-detail, .certificate-item, .experience-item, 
.teaching-content-item, .class-hour-item, .teaching-result-item, .ability-item {
  background-color: #f7f7f7;
  border-radius: 6px;
  padding: 12px 16px;
  margin-bottom: 12px;
  border: none;
  box-shadow: none;
}

.edu-item-detail:last-child, .certificate-item:last-child, 
.experience-item:last-child, .teaching-content-item:last-child, 
.class-hour-item:last-child, .teaching-result-item:last-child, 
.ability-item:last-child {
  margin-bottom: 0;
}

/* Consistent typography inside items */
.edu-item-detail p, .certificate-item p, .experience-item p, 
.teaching-content-item p, .class-hour-item p, .teaching-result-item p,
.ability-item strong, .ability-item span {
  margin-bottom: 4px;
  color: #000;
  font-size: 14px;
}

.edu-item-detail p:last-child, .certificate-item p:last-child, 
.experience-item p:last-child, .teaching-content-item p:last-child, 
.class-hour-item p:last-child, .teaching-result-item p:last-child {
  margin-bottom: 0;
}

/* Remove any conflicting styles for ability-item */
.ability-item {
  margin-bottom: 12px;
}

.ability-item:last-child {
  margin-bottom: 0;
}

/* 私聊老师按钮 */
.chat-teacher-button {
  margin-top: 12px;
  background-color: #F2C94C;
  border-color: #F2C94C;
  font-weight: 500;
}

.chat-teacher-button:hover {
  background-color: #FFCC00;
  border-color: #FFCC00;
}

/* 二维码弹窗样式 */
.qrcode-modal .ant-modal-header {
  background-color: transparent;
  border-bottom: none;
  padding-bottom: 0;
}

.qrcode-modal .ant-modal-title {
  color: #333;
  font-weight: bold;
}

.qrcode-modal .ant-btn-primary {
  background-color: #F2C94C;
  border-color: #F2C94C;
}

.qrcode-modal .ant-btn-primary:hover {
  background-color: #FFCC00;
  border-color: #FFCC00;
}

.qrcode-modal .ant-modal-body {
  padding: 24px;
  text-align: center;
}

.qrcode-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.qrcode-image {
  width: 240px;
  height: 240px;
  object-fit: contain;
  border: none;
  padding: 10px;
  margin-bottom: 15px;
}

.qrcode-tip {
  color: #666;
  font-size: 14px;
  margin-top: 10px;
}

@media (max-width: 576px) {
  .qrcode-image {
    width: 200px;
    height: 200px;
  }
  
  .chat-teacher-button {
    width: 100%;
    margin-top: 10px;
  }
}

/* 视频容器自适应样式 */
.detail-section .video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9比例 */
  height: 0;
  overflow: hidden;
  background-color: #f8f8f8;
  border-radius: 6px;
  margin-bottom: 15px;
}

.detail-section .video-container iframe,
.detail-section .video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.detail-section .video-error {
  padding: 15px;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 6px;
  margin-bottom: 15px;
}

/* 教育背景图片样式 */
.detail-section .education-proof {
  margin-top: 10px;
}

.detail-section .education-proof-image {
  max-width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: contain;
  border-radius: 6px;
  cursor: pointer;
  transition: transform 0.3s ease;
  display: block;
}

.detail-section .education-proof-image:hover {
  transform: scale(1.02);
}

/* 专业证书图片样式 */
.detail-section .certificate-image {
  margin-top: 10px;
}

.detail-section .certificate-image img {
  max-width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: contain;
  border-radius: 6px;
  cursor: pointer;
  transition: transform 0.3s ease;
  display: block;
}

.detail-section .certificate-image img:hover {
  transform: scale(1.02);
}

/* 响应式调整 */
@media (max-width: 576px) {
  .detail-section .education-proof-image,
  .detail-section .certificate-image img {
    max-height: 150px;
    border: none;
  }
  
  .detail-section .video-container {
    padding-bottom: 75%; /* 4:3比例，在小屏上更合适 */
  }
  
  .image-preview-modal {
    width: 95% !important;
    margin: 0 auto;
  }
  
  .image-preview {
    max-height: 60vh;
  }
  
  /* 移动端教育背景字体缩小 */
  .teacher-card .teacher-education .section-heading {
    font-size: calc(16px * 1.6 * 0.8 * 0.8) !important; /* 在原有基础上再缩小0.8倍 */
  }
  
  .teacher-card .teacher-education .edu-period {
    font-size: calc(13px * 1.6 * 0.8) !important; /* 缩小到0.8倍 */
  }
  
  .teacher-card .teacher-education .edu-school {
    font-size: calc(14px * 1.6 * 0.8 * 0.8) !important; /* 在原有基础上再缩小0.8倍 */
  }
  
  .teacher-card .teacher-education .edu-degree {
    font-size: calc(14px * 1.6 * 0.8 * 0.8) !important; /* 在原有基础上再缩小0.8倍 */
  }
}

/* 图片预览模态框样式 */
.image-preview-modal .ant-modal-header {
  background-color: transparent;
  border-bottom: none;
  padding-bottom: 0;
}

.image-preview-modal .ant-modal-title {
  color: #333;
  font-weight: bold;
}

.image-preview-modal .ant-modal-body {
  padding: 24px;
  text-align: center;
}

.image-preview-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-preview {
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  max-width: 100%;
  max-height: 70vh;
}

/* 教师卡片教育背景样式 - 电脑端 */
.teacher-card .teacher-education .section-heading {
  font-size: calc(16px * 1.6 * 0.8); /* 原来的大小 */
}

.teacher-card .teacher-education .edu-period {
  font-size: calc(13px * 1.6); /* 原来的大小 */
}

.teacher-card .teacher-education .edu-school {
  font-size: calc(14px * 1.6 * 0.8); /* 原来的大小 */
}

.teacher-card .teacher-education .edu-degree {
  font-size: calc(14px * 1.6 * 0.8); /* 原来的大小 */
}

/* 移动端适配 - 教师详情模态框顶部 */
@media (max-width: 576px) {
  .teacher-detail-header {
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 8px;
    margin-bottom: 5px;
  }
  
  /* 第一行：头像和姓名 */
  .teacher-detail-image-container {
    width: 60px;
    height: 60px;
    margin-right: 10px;
  }
  
  .teacher-detail-image-container::after {
    width: 18px;
    height: 18px;
    font-size: 12px;
  }
  
  .teacher-detail-info {
    flex: 1;
    min-width: 0;
  }
  
  .teacher-detail-info h2 {
    font-size: 24px;
    margin-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  /* 第二行：基本信息和聊天按钮 */
  .teacher-detail-basic-info {
    width: 100%;
    margin-top: 5px;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  
  .teacher-detail-info-item {
    margin-right: 12px;
    margin-bottom: 5px;
  }
  
  .teacher-detail-info-icon {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }
  
  .teacher-detail-info-text {
    font-size: 16px;
  }
  
  .teacher-detail-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-left: 0;
    margin-top: 8px;
  }
  
  .chat-teacher-button {
    width: 100%;
    height: 32px;
  }
}

/* 个人陈述部分特殊样式 */
.detail-section.personal-statement {
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
}

.detail-section.personal-statement h3 {
  display: flex;
  align-items: center;
  color: #000;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 600;
}

.detail-section.personal-statement h3::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  background-color: #fffbe6;
  border-radius: 4px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23FFCA28'%3E%3Cpath d='M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z'%3E%3C/path%3E%3C/svg%3E");
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: center;
  flex-shrink: 0;
}

/* 确保移动端样式一致 */
@media (max-width: 576px) {
  .detail-section.personal-statement {
    padding: 12px;
    margin-bottom: 16px;
  }
  
  .detail-section.personal-statement h3 {
    font-size: 16px;
  }
  
  .detail-section.personal-statement h3::before {
    width: 20px;
    height: 20px;
    background-size: 14px;
  }
}

/* 所有详情部分通用样式 */
.detail-section {
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
}

.detail-section:last-child {
  margin-bottom: 0;
}

.detail-section h3 {
  display: flex;
  align-items: center;
  color: #000;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 600;
}

.detail-section h3::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  background-color: #fffbe6;
  border-radius: 4px;
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: center;
  flex-shrink: 0;
}

/* 个人陈述部分 - 人像图标 */
.detail-section.personal-statement h3::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23FFCA28'%3E%3Cpath d='M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z'%3E%3C/path%3E%3C/svg%3E");
}

/* 视频介绍部分 - 播放图标 */
.detail-section.video-intro h3::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23FFCA28'%3E%3Cpath d='M8 5v14l11-7z'/%3E%3C/svg%3E");
}

/* 教育背景部分 - 学士帽图标 */
.detail-section.education-background h3::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23FFCA28'%3E%3Cpath d='M5 13.18v4L12 21l7-3.82v-4L12 17l-7-3.82zM12 3L1 9l11 6 9-4.91V17h2V9L12 3z'/%3E%3C/svg%3E");
}

/* 语言能力部分 - 语言图标 */
.detail-section.language-skills h3::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23FFCA28'%3E%3Cpath d='M12.87 15.07l-2.54-2.51.03-.03c1.74-1.94 2.98-4.17 3.71-6.53H17V4h-7V2H8v2H1v1.99h11.17C11.5 7.92 10.44 9.75 9 11.35 8.07 10.32 7.3 9.19 6.69 8h-2c.73 1.63 1.73 3.17 2.98 4.56l-5.09 5.02L4 19l5-5 3.11 3.11.76-2.04zM18.5 10h-2L12 22h2l1.12-3h4.75L21 22h2l-4.5-12zm-2.62 7l1.62-4.33L19.12 17h-3.24z'/%3E%3C/svg%3E");
}

/* 专业证书部分 - 证书图标 */
.detail-section.certificates h3::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23FFCA28'%3E%3Cpath d='M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-9 9h-2v-2h2v2zm0-4h-2V7h2v4z'/%3E%3C/svg%3E");
}

/* 教学经历部分 - 日历图标 */
.detail-section.teaching-experience h3::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23FFCA28'%3E%3Cpath d='M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z'/%3E%3C/svg%3E");
}

/* 教学内容部分 - 书本图标 */
.detail-section.teaching-content h3::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23FFCA28'%3E%3Cpath d='M21 5c-1.11-.35-2.33-.5-3.5-.5-1.95 0-4.05.4-5.5 1.5-1.45-1.1-3.55-1.5-5.5-1.5S2.45 4.9 1 6v14.65c0 .25.25.5.5.5.1 0 .15-.05.25-.05C3.1 20.45 5.05 20 6.5 20c1.95 0 4.05.4 5.5 1.5 1.35-.85 3.8-1.5 5.5-1.5 1.65 0 3.35.3 4.75 1.05.1.05.15.05.25.05.25 0 .5-.25.5-.5V6c-.6-.45-1.25-.75-2-1zm0 13.5c-1.1-.35-2.3-.5-3.5-.5-1.7 0-4.15.65-5.5 1.5V8c1.35-.85 3.8-1.5 5.5-1.5 1.2 0 2.4.15 3.5.5v11.5z'/%3E%3C/svg%3E");
}

/* 课时标准部分 - 时钟图标 */
.detail-section.class-hour-standards h3::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23FFCA28'%3E%3Cpath d='M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm.5-13H11v6l5.25 3.15.75-1.23-4.5-2.67z'/%3E%3C/svg%3E");
}

/* 教学成果部分 - 奖杯图标 */
.detail-section.teaching-results h3::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23FFCA28'%3E%3Cpath d='M19 5h-2V3H7v2H5c-1.1 0-2 .9-2 2v1c0 2.55 1.92 4.63 4.39 4.94.63 1.5 1.98 2.63 3.61 2.96V19H7v2h10v-2h-4v-3.1c1.63-.33 2.98-1.46 3.61-2.96C19.08 14.63 21 12.55 21 10V7c0-1.1-.9-2-2-2zM7 10.82C5.84 10.4 5 9.3 5 8V7h2v3.82zM19 8c0 1.3-.84 2.4-2 2.82V7h2v1z'/%3E%3C/svg%3E");
}

/* 确保移动端样式一致 */
@media (max-width: 576px) {
  .detail-section {
    padding: 12px;
    margin-bottom: 16px;
  }
  
  .detail-section h3 {
    font-size: 16px;
  }
  
  .detail-section h3::before {
    width: 20px;
    height: 20px;
    background-size: 14px;
  }
}

.detail-section .edu-item-detail,
.detail-section .certificate-item, 
.detail-section .experience-item, 
.detail-section .teaching-content-item, 
.detail-section .class-hour-item, 
.detail-section .teaching-result-item, 
.detail-section .ability-item {
  background-color: #f7f7f7;
}

/* 课时标准卡片布局 */
.class-hour-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 16px;
  margin-top: 10px;
}

.class-hour-card {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

.class-hour-icon {
  width: 50px;
  height: 50px;
  background-color: #fffbe6;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 12px;
  color: #FFCA28;
  font-size: 24px;
}

.class-hour-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #333;
}

.class-hour-price {
  font-size: 18px;
  font-weight: 500;
  color: #333;
}

/* 添加响应式样式 */
@media (max-width: 576px) {
  .class-hour-grid {
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    gap: 10px;
  }
  
  .class-hour-card {
    padding: 12px;
  }
  
  .class-hour-icon {
    width: 40px;
    height: 40px;
    font-size: 20px;
    margin-bottom: 8px;
  }
  
  .class-hour-title {
    font-size: 14px;
    margin-bottom: 4px;
  }
  
  .class-hour-price {
    font-size: 16px;
  }
}

/* 添加教育背景、教学经历、专业证书和教学成果项目前的彩色竖线 */
.edu-item-detail, 
.experience-item, 
.certificate-item, 
.teaching-result-item {
  position: relative;
  padding-left: 16px;
  border-left: none !important;
}

.edu-item-detail::before, 
.experience-item::before, 
.certificate-item::before, 
.teaching-result-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 4px;
  border-radius: 2px;
}

/* 为每种类型设置不同的颜色 */
.edu-item-detail::before {
  background-color: #FFCA28; /* 金色 - 统一使用金色 */
}

.experience-item::before {
  background-color: #FFCA28; /* 金色 - 统一使用金色 */
}

.certificate-item::before {
  background-color: #FFCA28; /* 金色 - 统一使用金色 */
}

.teaching-result-item::before {
  background-color: #FFCA28; /* 金色 - 统一使用金色 */
}

/* 欢迎弹窗样式 */
.welcome-modal .ant-modal-content {
  border-radius: 12px;
  padding: 0;
  overflow: hidden;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
}

.welcome-modal .ant-modal-header {
  background-color: #FFCA28;
  padding: 16px 24px;
  border-bottom: none;
}

.welcome-modal .ant-modal-title {
  color: #333;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
}

.welcome-modal .ant-modal-body {
  padding: 30px;
  text-align: center;
}

.welcome-message {
  font-size: 16px;
  line-height: 1.8;
  color: #333;
  margin-bottom: 24px;
}

.welcome-modal .ant-modal-footer {
  border-top: none;
  padding: 0 24px 24px;
  text-align: center;
}

.welcome-modal .ant-btn-primary {
  background-color: #FFCA28;
  border-color: #FFCA28;
  color: #333;
  font-weight: 500;
  min-width: 120px;
  height: 40px;
}

.welcome-modal .ant-btn-primary:hover {
  background-color: #FFD54F;
  border-color: #FFD54F;
}

.welcome-modal .welcome-icon {
  font-size: 48px;
  color: #FFCA28;
  margin-bottom: 16px;
}